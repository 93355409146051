import React from "react"

const LessonItem = ({ title, children }) => (
  <div className="flex">
    <div className="flex-shrink-0">
      <div className="flex items-center justify-center h-12 w-12 rounded-md bg-orange-500 text-white">
        <svg
          className="h-8 w-8"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
            clipRule="evenodd"
          />
        </svg>
      </div>
    </div>
    <div className="ml-4">
      <h5 className="text-lg leading-6 font-medium text-gray-900">{title}</h5>
      <p className="mt-2 text-base leading-6 text-gray-500">{children}</p>
    </div>
  </div>
)

export default LessonItem
