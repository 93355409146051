import React from "react"

const SampleVideo = () => (
  <div className="py-16 bg-white overflow-hidden lg:py-24">
    <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl">
      <div className="relative">
        <h3
          id="sample-lesson"
          className="mb-4 text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10"
        >
          Check out a sample lesson
        </h3>
      </div>
      <div className="relative h-full pb-2/3 overflow-hidden">
        <iframe
          className="absolute inset-0 rounded-lg shadow-lg"
          title="Testing LiveView talk from ElixirConf 2020"
          width="100%"
          height="100%"
          src="https://player.vimeo.com/video/501768349"
          frameBorder="0"
          allow="accelerometer; autoplay; fullscreen; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  </div>
)

export default SampleVideo
