import React from "react"

import AboutMe from "../components/about-me"
import Hero from "../components/hero"
import Intro from "../components/intro"
import CourseOutline from "../components/course-outline"
import FAQs from "../components/faqs"
import Footer from "../components/footer"
import Pricing from "../components/pricing"
import SampleVideo from "../components/sample-video"
import SEO from "../components/seo"
import WRTestimonial from "../components/wr-testimonial"
import NickMaxwellTestimonial from "../components/nick-maxwell-testimonial"
import IanMacDonaldTestimonial from "../components/ian-macdonald-testimonial"
import AswinMohanTestimonial from "../components/aswin-mohan-testimonial"
import MikeClarkTestimnoial from "../components/mike-clark-testimonial"
import Analytics from "../components/analytics"

const IndexPage = () => {
  return (
    <>
      <Analytics />
      <SEO title="Testing LiveView" />
      <Hero />
      <Intro />
      <WRTestimonial />
      <CourseOutline />
      <NickMaxwellTestimonial />
      <SampleVideo />
      <AswinMohanTestimonial />
      <Pricing />
      <MikeClarkTestimnoial />
      <FAQs />
      <IanMacDonaldTestimonial />
      <AboutMe />
      <Footer />
    </>
  )
}

export default IndexPage
