import React from "react"

import EmailMe from "./email-me"
import Link from "./link"

const FAQs = () => (
  <div className="bg-gray-50">
    <div className="max-w-screen-xl mx-auto py-16 px-4 sm:px-6 lg:py-20 lg:px-8">
      <div className="lg:grid lg:grid-cols-3 lg:gap-8">
        <div className="space-y-4">
          <h2
            id="faqs"
            className="text-3xl leading-9 font-extrabold text-gray-900"
          >
            Frequently asked questions
          </h2>
          <p className="text-lg leading-3 text-gray-500">
            Can’t find the answer you’re looking for?
          </p>
          <p className="text-lg leading-3 text-gray-500">
            <a
              href="mailto:testingliveview@germanvelasco.com"
              className="underline font-medium text-orange-600 hover:text-orange-500 transition duration-150 ease-in-out"
            >
              Send me an email
            </a>
          </p>
        </div>
        <div className="mt-12 lg:mt-0 lg:col-span-2">
          <dl className="space-y-12">
            <div className="space-y-2">
              <dt className="text-lg leading-6 font-medium text-gray-900">
                Who is this course for?
              </dt>
              <dd className="text-base leading-6 text-gray-500">
                Developers who want to learn to test LiveView effectively,
                without creating tests that are brittle, slow, and hard to
                maintain.
              </dd>
            </div>
            <div className="space-y-2">
              <dt className="text-lg leading-6 font-medium text-gray-900">
                What are the videos like?
              </dt>
              <dd className="text-base leading-6 text-gray-500">
                Tons of live coding. You'll learn from the contents of the
                videos, but you'll also see my process for testing and
                interacting with code. You might even get inspired by my TDD
                flow and the editor setup that lets me run tests at the speed of
                thought.
              </dd>
            </div>
            <div className="space-y-2">
              <dt className="text-lg leading-6 font-medium text-gray-900">
                How many lessons are there?
              </dt>
              <dd className="text-base leading-6 text-gray-500">
                There are 28 lessons, each created to cover a single topic.
                Together they make over four and a half hours of content.
              </dd>
            </div>
            <div className="space-y-2">
              <dt className="text-lg leading-6 font-medium text-gray-900">
                Do I need to know any LiveView?
              </dt>
              <dd className="text-base leading-6 text-gray-500">
                I assume knowledge of LiveView in the course. Though you don't
                need to be an expert, it helps if you've seen it before. If
                you've never used LiveView, I recommend{" "}
                <Link to="https://pragmaticstudio.com/phoenix-liveview">
                  The Pragmatic Studio's LiveView Course
                </Link>
                .
              </dd>
            </div>
            <div className="space-y-2">
              <dt className="text-lg leading-6 font-medium text-gray-900">
                Can I buy this for my team?
              </dt>
              <dd className="text-base leading-6 text-gray-500">
                Definitely! <EmailMe>Send me an email</EmailMe>, and I can offer
                a team license (with a discount) that fits your team size.
              </dd>
            </div>
            <div className="space-y-2">
              <dt className="text-lg leading-6 font-medium text-gray-900">
                Can I get a refund if I don't like the course?
              </dt>
              <dd className="text-base leading-6 text-gray-500">Absolutely.</dd>
            </div>
            <div className="space-y-2">
              <dt className="text-lg leading-6 font-medium text-gray-900">
                What if the course is too expensive for me because I live in a
                different country?
              </dt>
              <dd className="text-base leading-6 text-gray-500">
                No problem, I'm happy to give you a discount. Just{" "}
                <EmailMe>send me an email</EmailMe> and the country where you
                live, along with a suggested reasonable price for you in your
                country.
              </dd>
            </div>
            <div className="space-y-2">
              <dt className="text-lg leading-6 font-medium text-gray-900">
                Is there an update coming?
              </dt>
              <dd className="text-base leading-6 text-gray-500">
                Nice mind reading!{" "}
                <span role="img" aria-label="smily face">
                  😀
                </span>{" "}
                Yes, I'm working on an update. If you want to find more about it
                and get exclusive insights into the process, you can{" "}
                <Link to="/update">sign up for updates</Link>.
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  </div>
)

export default FAQs
