import React from "react"

const PricingListItem = ({ children }) => (
  <li className="flex items-start">
    <div className="flex-shrink-0">
      <svg
        className="h-6 w-6 text-green-500"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M5 13l4 4L19 7"
        />
      </svg>
    </div>
    <p className="ml-3 text-base leading-6 text-gray-700">{children}</p>
  </li>
)

export default PricingListItem
