import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Testimonial from "./testimonial"

const NickMaxwellTestimonial = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "nick_maxwell_400x400.jpg" }) {
        childImageSharp {
          fixed(width: 400) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <Testimonial
      from="Nick Maxwell"
      image={data.file.childImageSharp.fixed.src}
      original_url="https://twitter.com/CodiferousCoder/status/1416441619419652099"
    >
      So, <span className="text-blue-500 font-normal">@germsvel</span>'s
      "Testing LiveView"{" "}
      <span className="text-blue-500 font-normal">
        #elixirlang #phoenixframework
      </span>{" "}
      course is really great.{" "}
      <span className="font-bold">Tons of actionable info, and zero fluff</span>
      .
    </Testimonial>
  )
}

export default NickMaxwellTestimonial
