import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Testimonial from "./testimonial"

const MikeClarkTestimonial = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "mike_clark_400x400.jpeg" }) {
        childImageSharp {
          fixed(width: 400) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <div className="bg-gray-50">
      <Testimonial
        from="Mike Clark"
        image={data.file.childImageSharp.fixed.src}
        original_url="https://twitter.com/clarkware/status/1473315650353303552"
      >
        <span className="font-bold">Highly recommend this course!</span> German
        is my go-to for LiveView testing, and I’m thrilled he took the time to
        create this comprehensive, thoughtful course.
      </Testimonial>
    </div>
  )
}

export default MikeClarkTestimonial
