import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Testimonial from "./testimonial"

const AswinMohanTestimonial = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "aswin_mohan_400x400.jpg" }) {
        childImageSharp {
          fixed(width: 400) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <Testimonial
      from="Aswin Mohan"
      image={data.file.childImageSharp.fixed.src}
      original_url="https://twitter.com/aswinmohanme/status/1473263451493437445"
    >
      Took the course to improve{" "}
      <span
        className="text-blue-500
      font-normal"
      >
        @indiepaperme
      </span>{" "}
      and check out if LiveView was ready for production. Ended up converting
      all pages to LiveView all covered by tests and production patterns I
      learnt from the course.
      <br />
      <br />
      <span className="font-bold">10/10 Recommended for LiveView.</span>
    </Testimonial>
  )
}

export default AswinMohanTestimonial
