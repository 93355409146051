import React from "react"

const Testimonial = ({ from, image, original_url, children }) => (
  <section className="py-12 overflow-hidden md:py-20 lg:py-24">
    <div className="relative max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="relative">
        <blockquote className="mt-8">
          <div className="max-w-3xl mx-auto text-center text-2xl leading-9 font-medium text-gray-900">
            <div className="flex">
              <svg
                className="flex-shrink-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-orange-600"
                fill="currentColor"
                viewBox="0 0 32 32"
              >
                <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
              </svg>
              <p>
                {original_url ? (
                  <a href={original_url}>{children}</a>
                ) : (
                  children
                )}
              </p>
            </div>
          </div>
          <footer className="mt-8">
            <div className="md:flex md:items-center md:justify-center">
              {image && (
                <div className="md:flex-shrink-0">
                  <img
                    className="mx-auto h-10 w-10 rounded-full"
                    src={image}
                    alt={from}
                  />
                </div>
              )}
              <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                <div className="text-base leading-6 font-medium text-gray-900">
                  {!image && "— "}
                  {from}
                </div>
              </div>
            </div>
          </footer>
        </blockquote>
      </div>
    </div>
  </section>
)

export default Testimonial
