import React from "react"

import ListItem from "./list-item"
import CrossListItem from "./cross-list-item"

const Intro = () => (
  <div className="bg-white text-gray-900">
    <div className="max-w-screen-xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <h2 className="text-3xl leading-9 font-extrabold">
          Write tests that don't slow you down
        </h2>
        <p className="mt-4 text-lg leading-7 text-gray-800">
          Phoenix LiveView brings a{" "}
          <span className="font-semibold">new set of testing tools</span> and
          requires a <span className="font-semibold">new way of thinking</span>{" "}
          about our tests.
        </p>
        <p className="mt-4 text-lg leading-7 text-gray-800">
          Don't write tests that:
        </p>
        <CrossListItem>only the original author understands</CrossListItem>
        <CrossListItem>
          fail intermittently, costing you hundreds of hours of development time
        </CrossListItem>
        <CrossListItem>
          run so slow you check Twitter every time you run them
        </CrossListItem>
        <p className="mt-8 text-lg leading-7 text-gray-800">
          Instead, master{" "}
          <span className="font-semibold">LiveView's new testing tools</span>,{" "}
          learn the <span className="font-semibold">thought process</span>{" "}
          behind what, when, and how to test LiveView, and rediscover the joy of
          having tests that are{" "}
        </p>
        <div className="font-semibold">
          <ListItem>
            fast{" "}
            <span role="img" aria-label="race car">
              🏎
            </span>
          </ListItem>
          <ListItem>
            robust{" "}
            <span role="img" aria-label="flexed bicep">
              💪
            </span>
            , and
          </ListItem>
          <ListItem>
            easy to maintain{" "}
            <span role="img" aria-label="hammer and wrench">
              🛠
            </span>
          </ListItem>
        </div>
      </div>
    </div>
  </div>
)

export default Intro
