import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import LessonItem from "./lesson-item"

const CourseOutline = () => {
  const data = useStaticQuery(graphql`
    query WatwitterImage {
      watwitterImage: file(relativePath: { eq: "watwitter-sample-long.png" }) {
        childImageSharp {
          fixed(width: 490) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      lessonImage: file(relativePath: { eq: "lesson-sample.png" }) {
        childImageSharp {
          fixed(width: 490) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <div className="py-16 bg-white overflow-hidden lg:py-24">
      <div className="relative max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl">
        <svg
          className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4"
          width="404"
          // height="784"
          height="884"
          fill="none"
          viewBox="0 0 404 784"
        >
          <defs>
            <pattern
              id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
              x="0"
              y="0"
              width="20"
              height="20"
              patternUnits="userSpaceOnUse"
            >
              <rect
                x="0"
                y="0"
                width="4"
                height="4"
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width="404"
            height="784"
            fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)"
          />
        </svg>

        <div className="relative max-w-3xl mx-auto">
          <h3
            id="course-outline"
            className="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10"
          >
            The Course Outline
          </h3>
          <p className="mt-4 max-w-3xl mx-auto text-center text-xl leading-7 text-gray-500">
            We'll cover everything you need to know. From fundamentals to
            mastery. And I'll keep publishing videos for testing-related changes
            until LiveView hits 1.0!
          </p>
        </div>

        <div className="relative max-w-3xl mx-auto lg:max-w-full mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div className="relative">
            <h4 className="text-2xl leading-8 font-extrabold text-gray-900 tracking-tight sm:text-3xl sm:leading-9">
              Testing a twitter clone
            </h4>
            <p className="mt-3 text-lg leading-7 text-gray-500">
              Throughout the course we'll test (and build) a twitter clone, a
              real application full of trade-offs and edge cases.
            </p>

            <ul className="mt-10">
              <li>
                <LessonItem title="Testing Rendering">
                  The basics of setting up a LiveView test and making sure the
                  initial page renders the correct information.
                </LessonItem>
              </li>
              <li className="mt-10">
                <LessonItem title="Scoping Assertions">
                  Let's get more precise about what we expect from LiveView's
                  UI.
                </LessonItem>
              </li>
              <li className="mt-10">
                <LessonItem title="Testing Rendering Components">
                  A parent LiveView renders a list of LiveComponents. What
                  should we test in the LiveView, and how should we test it?
                </LessonItem>
              </li>
              <li className="mt-10">
                <LessonItem title="Testing Live Components (Parts 1 & 2)">
                  How to test LiveComponents, what to test in them, and how to
                  target elements that aren't simple text.
                </LessonItem>
              </li>
              <li className="mt-10">
                <LessonItem title="Testing Page Interactions">
                  Test interactivity from the perspective of the user. Targeting
                  elements, clicking them, and expecting responses users can
                  see.
                </LessonItem>
              </li>
              <li className="mt-10">
                <LessonItem title="Testing Live Patch">
                  When to test and when <span className="italic">not</span> to
                  test live patch. And what are the alternatives?
                </LessonItem>
              </li>
              <li className="mt-10">
                <LessonItem title="Testing Live Navigation">
                  How to test navigation from LiveViews to other LiveViews and
                  non-LiveViews.
                </LessonItem>
              </li>
              <li className="mt-10">
                <LessonItem title="Testing Forms">
                  Testing form submissions, changes, validations, and
                  redirections.
                </LessonItem>
              </li>
              <li className="mt-10">
                <LessonItem title="Testing Event-Driven UIs (Parts 1 & 2)">
                  Testing event-driven updates to our Twitter clone.
                </LessonItem>
              </li>
            </ul>
          </div>

          <div className="mt-10 -mx-4 relative lg:mt-0">
            <svg
              className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
              width="784"
              height="404"
              fill="none"
              viewBox="0 0 784 404"
            >
              <defs>
                <pattern
                  id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width="784"
                height="404"
                fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)"
              />
            </svg>
            <img
              className="relative mx-auto"
              width="490"
              src={data.watwitterImage.childImageSharp.fixed.src}
              alt="Watwitter (our twitter clone) Timeline"
            />
          </div>
        </div>

        <svg
          className="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12"
          width="404"
          height="784"
          fill="none"
          viewBox="0 0 404 784"
        >
          <defs>
            <pattern
              id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
              x="0"
              y="0"
              width="20"
              height="20"
              patternUnits="userSpaceOnUse"
            >
              <rect
                x="0"
                y="0"
                width="4"
                height="4"
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width="404"
            height="784"
            fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
          />
        </svg>

        <div className="relative max-w-3xl mx-auto lg:max-w-full mt-12 sm:mt-16 lg:mt-24">
          <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
            <div className="lg:col-start-2">
              <ul className="mt-10">
                <li>
                  <LessonItem title="Displaying New Posts">
                    Test users interacting with a page to show new posts.
                  </LessonItem>
                </li>
                <li className="mt-10">
                  <LessonItem title="Testing a User Can Like a Post (Parts 1-3)">
                    A deep dive into considering how to test interactions
                    between LiveView, stateful LiveComponents, and the rest of
                    our application.
                  </LessonItem>
                </li>
                <li className="mt-10">
                  <LessonItem title="Testing Broadcasting Likes (Parts 1 & 2)">
                    Seeing our own UI react to changes is not enough. We want to
                    harness the power of Phoenix so that all users can see those
                    changes too.
                  </LessonItem>
                </li>
                <li className="mt-10">
                  <LessonItem title="Making tests easy to read and understand">
                    As developers we spend a large portion of our time reading
                    code. Let's see how to write our tests in a way that our
                    colleagues (and our future selves) will easily understand.
                  </LessonItem>
                </li>
                <li className="mt-10">
                  <LessonItem title="Debugging LiveView Tests">
                    A quick dive into using the wonderful open_browser/1 helper
                    to debug LiveView tests
                  </LessonItem>
                </li>
                <li className="mt-10">
                  <LessonItem title="Testing JavaScript Hooks">
                    Testing that we can scroll infinitely with JavaScript hooks.
                  </LessonItem>
                </li>
                <li className="mt-10">
                  <LessonItem title="Complex Assertions with Floki">
                    When the built-in LiveView tooling isn't enough, we drop
                    into a lower-level of abstraction and use Floki to improve
                    our assertions.
                  </LessonItem>
                </li>
                <li className="mt-10">
                  <LessonItem title="Testing Uploads (Parts 1-4)">
                    Whether you end up choosing to upload images to your server
                    or to the cloud, we cover how to be confident that our
                    application is uploading, saving, and displaying those
                    uploads correctly.
                  </LessonItem>
                </li>
              </ul>
            </div>

            <div className="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
              <svg
                className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
                width="784"
                height="404"
                fill="none"
                viewBox="0 0 784 404"
              >
                <defs>
                  <pattern
                    id="e80155a9-dfde-425a-b5ea-1f6fadd20131"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x="0"
                      y="0"
                      width="4"
                      height="4"
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width="784"
                  height="404"
                  fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)"
                />
              </svg>
              <img
                className="relative mx-auto"
                width="490"
                src={data.lessonImage.childImageSharp.fixed.src}
                alt="Terminal showing test error"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CourseOutline
