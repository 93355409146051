import React from "react"

import PricingListItem from "./pricing-list-item"

const Pricing = () => (
  <div className="bg-gray-900">
    <div className="pt-12 sm:pt-16 lg:pt-24">
      <div className="max-w-screen-xl mx-auto text-center px-4 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto space-y-2 lg:max-w-none">
          <h2
            id="pricing"
            className="text-lg leading-6 font-semibold text-gray-300 uppercase tracking-wider"
          >
            Pricing
          </h2>
        </div>
      </div>
    </div>
    <div className="mt-8 pb-12 bg-gray-50 sm:mt-12 sm:pb-16 lg:mt-16 lg:pb-24">
      <div className="relative">
        <div className="absolute inset-0 h-3/4 bg-gray-900"></div>
        <div className="relative z-10 max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-md mx-auto space-y-4 lg:space-y-0">
            <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
              <div className="px-6 py-8 bg-white sm:p-10 sm:pb-6">
                <div>
                  <h3
                    className="inline-flex px-4 py-1 rounded-full text-sm leading-5 font-semibold tracking-wide uppercase bg-orange-100 text-orange-600"
                    id="tier-full"
                  >
                    The Full Course
                  </h3>
                </div>
                <div className="mt-4 flex items-baseline text-6xl leading-none font-extrabold">
                  $99
                </div>
              </div>
              <div className="flex-1 flex flex-col justify-between px-6 pt-6 pb-8 bg-gray-50 space-y-6 sm:p-10 sm:pt-6">
                <ul className="space-y-4">
                  <PricingListItem>All videos</PricingListItem>

                  <PricingListItem>
                    Access to the source code with detailed commits
                  </PricingListItem>

                  <PricingListItem>
                    Comment on any lesson along with other Elixirists
                  </PricingListItem>

                  <PricingListItem>
                    Detailed notes for all videos, so you can quickly reference
                    anything without having to scrub through the video
                  </PricingListItem>

                  <PricingListItem>
                    Suggest topics for future videos
                  </PricingListItem>

                  <PricingListItem>
                    Message me about a difficult LiveView testing scenario
                    you're currently experiencing, and I'll do my best to help,
                    or even make a lesson of it!
                  </PricingListItem>
                </ul>
                <div className="rounded-md shadow">
                  <a
                    href="https://courses.germanvelasco.com/testing-liveview/buy"
                    className="flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:border-orange-700 focus:ring-orange active:bg-orange-800 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
                    aria-describedby="tier-full"
                  >
                    Get the Full Course
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Pricing
