import React from "react"

import Testimonial from "./testimonial"

const IanMacDonaldTestimonial = () => {
  return (
    <div className="bg-gray-50">
      <Testimonial from="Ian MacDonald">
        I can't thank you enough for the course!{" "}
        <span className="font-bold">
          It's allowed me to get comfortable with Elixir and Phoenix LiveView
          quickly
        </span>{" "}
        as my career shifted from data science (C++, Python, R) to web-app
        testing & development.
      </Testimonial>
    </div>
  )
}

export default IanMacDonaldTestimonial
