import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Testimonial from "./testimonial"

const WRTestimonial = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "will_ricketts_400x400.jpg" }) {
        childImageSharp {
          fixed(width: 400) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <Testimonial
      from="Will Ricketts"
      image={data.file.childImageSharp.fixed.src}
      original_url="https://twitter.com/willricketts/status/1352696619687243776"
    >
      If you're getting into LiveView, give this a look! Can't endorse this
      enough <span className="text-blue-500 font-normal">#myelixirstatus</span>
    </Testimonial>
  )
}

export default WRTestimonial
